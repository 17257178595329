<template>
  <div class="com-learn-OrgTree align-self-baseline">
    <div class="tree-panel">
      <a-tree
        :replaceFields="{
          children: 'childs',
          title: 'name',
          key: 'id',
        }"
        v-if="source.treeData.length"
        :tree-data="source.treeData"
        :selectable="false"
        block-node
        :default-expanded-keys="defaultExpandedKeys"
      >
        <template #switcherIcon>
          <span>
            <cs-icon name="icon-shouqi" type="symbol" class="node-icon" />
            <cs-icon name="icon-zhankai" type="symbol" class="node-icon" />
          </span>
        </template>
        <template #title="item">
          <div
            @click="selectClick(item)"
            :class="[
              'node-level',
              `level-${item.level}`,
              { 'level-active': item.id == active },
            ]"
          >
            {{ item.name }}
          </div>
        </template>
      </a-tree>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      source: {
        treeData: [],
      },
      defaultExpandedKeys: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.api.service.fpc_oapi_study_organ_treeList().then(async (res) => {
        await this.api.toast(res, 0);
        const { data } = res;
        // 展开一、二级
        const defaultExpandedKeys = [
          data.id,
          // ...data.childs.map((ele) => ele.id),
        ];
        this.defaultExpandedKeys = defaultExpandedKeys;
        this.source.treeData = [data];
        this.selectClick({ id: data.id, name: data.name, level: data.level });
      });
    },
    selectClick(item) {
      this.$emit("search", item);
    },
  },
};
</script>
<style lang="less" scoped>
.com-learn-OrgTree {
  width: 260px;
  overflow: auto;
  min-height: 765px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .tree-panel {
    padding: 30px 10px;
    display: table;
  }
  /deep/ .ant-tree {
    min-width: 240px;
    & > li > .ant-tree-switcher {
      display: none;
    }
    & > li > .ant-tree-node-content-wrapper {
      width: 100%;
      text-indent: 10px;
    }
    .node-icon {
      margin-top: 13px;
    }
    .ant-tree-switcher_open {
      svg {
        &:nth-child(1) {
          display: none;
        }
      }
    }
    .ant-tree-switcher_close {
      svg {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .level-1 {
      font-weight: bold;
    }
    .level-2 {
      color: #202121;
    }
    .ant-tree-child-tree > li:first-child {
      padding-top: 0px;
    }
    li {
      padding: 0;
    }
    .node-level {
      padding: 10px;
      &::before {
        content: " ";
        display: block;
        width: 1px;
        height: 44px;
        float: left;
        background: #e6e7e7;
        position: relative;
        margin-top: -10px;
      }
      &.level-1,
      &.level-2 {
        &::before {
          display: none;
        }
      }
      .level-border-style(@num) {
        &::before {
          margin-left: @num * -18px - 30px;
        }
      }
      &.level-3 {
        .level-border-style(1);
      }
      &.level-4 {
        .level-border-style(2);
      }
      &.level-5 {
        .level-border-style(3);
      }
      &.level-6 {
        .level-border-style(4);
      }
      &.level-7 {
        .level-border-style(5);
      }
      &.level-8 {
        .level-border-style(6);
      }
      &.level-9 {
        .level-border-style(7);
      }
      &.level-10 {
        .level-border-style(8);
      }

      &.level-active {
        color: @primary-color;
        background: #f5f7f9;
        border-radius: 4px;
        &::before {
          background: @primary-color;
        }
      }
      &:hover {
        color: #727878;
        background: #f5f7f9;
        border-radius: 4px;
      }
    }
    .ant-tree-node-content-wrapper {
      height: auto !important;
      &:hover {
        background: transparent;
      }
    }
  }
}
</style>