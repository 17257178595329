var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"com-learn-OrgTree align-self-baseline"},[_c('div',{staticClass:"tree-panel"},[(_vm.source.treeData.length)?_c('a-tree',{attrs:{"replaceFields":{
        children: 'childs',
        title: 'name',
        key: 'id',
      },"tree-data":_vm.source.treeData,"selectable":false,"block-node":"","default-expanded-keys":_vm.defaultExpandedKeys},scopedSlots:_vm._u([{key:"switcherIcon",fn:function(){return [_c('span',[_c('cs-icon',{staticClass:"node-icon",attrs:{"name":"icon-shouqi","type":"symbol"}}),_c('cs-icon',{staticClass:"node-icon",attrs:{"name":"icon-zhankai","type":"symbol"}})],1)]},proxy:true},{key:"title",fn:function(item){return [_c('div',{class:[
            'node-level',
            ("level-" + (item.level)),
            { 'level-active': item.id == _vm.active } ],on:{"click":function($event){return _vm.selectClick(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,585103292)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }